<template>
  <div>
    <button
      style="display: none"
      type="button"
      class="show-modal-erp-detail"
      data-bs-toggle="modal"
      data-bs-target="#modalErpDetail"
    ></button>
    <div
      class="modal fade"
      id="modalErpDetail"
      tabindex="-1"
      aria-labelledby="modalErpDetailLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <div class="d-flex align-items-center">
              <h5 class="modal-title me-3">Invoice Detail</h5>
              <div
                v-if="isDraft"
                style="
                  background-color: red;
                  color: #fff;
                  padding: 2px 10px;
                  font-weight: bold;
                "
              >
                Draft
              </div>
            </div>
            <div class="d-flex align-items-center">
              <div class="me-5" v-if="erp.uploaddate">
                <strong>Upload Time: </strong>{{ erp.uploaddate }} | Status:
                <span v-if="erp.uploaded == 1" class="text-success"
                  >Success</span
                ><span v-else class="text-danger">Failed</span>
              </div>
              <i
                style="cursor: pointer"
                class="material-icons btn-close-modal justify-content-end"
                data-bs-dismiss="modal"
                >clear</i
              >
            </div>
          </div>
          <div class="modal-body">
            <div class="general">
              <div class="row mt-3">
                <div class="col-lg-4 col-6">
                  <label class="fw-bold" for> Customer Code </label>
                  <material-input
                    v-model="erp.customercode"
                    type="text"
                    id="customer_code"
                    name="customer_code"
                    :disabled="!isEdit"
                  />
                </div>
                <div class="col-lg-4 col-6">
                  <label class="fw-bold" for> Credit Term </label>
                  <material-input
                    v-model="erp.credittermcode"
                    type="text"
                    id="credit_term"
                    name="credit_term"
                    :disabled="!isEdit"
                  />
                </div>
                <div class="col-lg-4 col-6">
                  <label class="fw-bold" for> Currency </label>
                  <material-input
                    v-model="erp.basecurrency"
                    type="text"
                    id="currency"
                    name="currency"
                    :disabled="!isEdit"
                  />
                </div>
                <div class="col-lg-4 col-6">
                  <label class="fw-bold" for>
                    Document Number <span class="text-danger ms-2">*</span>
                  </label>
                  <material-input
                    v-model="erp.documentnumber"
                    type="text"
                    id="document_number"
                    name="document_number"
                    :disabled="!isEdit"
                  />
                  <span
                    class="is-invalid"
                    v-if="fieldRequired.isDocumentNumberRequired"
                    >The field is required.</span
                  >
                </div>
                <div class="col-lg-4 col-6">
                  <label class="fw-bold" for> Delivery Mode </label>
                  <material-input
                    v-model="erp.deliverymodecode"
                    type="text"
                    id="delivery_mode"
                    name="delivery_mode"
                    :disabled="!isEdit"
                  />
                </div>
                <div class="col-lg-4 col-6">
                  <label class="fw-bold" for> Base Rate (SGD) </label>
                  <material-input
                    type="text"
                    id="base_rate"
                    name="base_rate"
                    :disabled="!isEdit"
                  />
                </div>
                <div class="col-lg-4 col-6">
                  <label class="fw-bold" for> Document Date </label>
                  <Datepicker
                    :enableTimePicker="false"
                    class="date-time-dashboad"
                    v-model="erp.documentdate"
                    auto-apply
                    :format="format"
                    :disabled="!isEdit"
                  />
                </div>
                <div class="col-lg-4 col-6">
                  <label class="fw-bold" for> Cost Center </label>
                  <material-input
                    v-model="erp.costcentercode"
                    type="text"
                    id="cost_center"
                    name="cost_center"
                    :disabled="!isEdit"
                  />
                </div>
                <div class="col-lg-4 col-6">
                  <label class="fw-bold" for> Remarks </label>
                  <material-input
                    v-model="erp.remarks"
                    type="text"
                    id="remarks"
                    name="remarks"
                    :disabled="!isEdit"
                  />
                </div>
                <div class="col-lg-4 col-6">
                  <label class="fw-bold" for> Document Ref </label>
                  <material-input
                    v-model="erp.documentreference"
                    type="text"
                    id="document_ref"
                    name="document_ref"
                    :disabled="!isEdit"
                  />
                </div>
                <div class="col-lg-4 col-6">
                  <label class="fw-bold" for> Permit Number </label>
                  <material-input
                    v-model="erp.permitnumber"
                    type="text"
                    id="permit_number"
                    name="permit_number"
                    :disabled="!isEdit"
                  />
                </div>
                <div class="col-lg-4 col-6">
                  <label class="fw-bold" for> Team Member </label>
                  <material-input
                    v-model="erp.teammember"
                    type="text"
                    id="team_member"
                    name="team_member"
                    :disabled="!isEdit"
                  />
                </div>
                <div class="col-lg-4 col-6 d-flex align-items-center">
                  <material-checkbox
                    class="align-items-center checkbox-custom mb-0"
                    v-model="erp.exclusivetax"
                    :checked="erp.exclusivetax"
                    :isDisabled="!isEdit"
                  ></material-checkbox>
                  <div class="mt-1 ms-2">Exclusive Tax</div>
                </div>
              </div>
            </div>
            <div class="group-sales mt-5">
              <div class="table-responsive p-0 custom-table">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-center font-weight-bolder">Seq</th>
                      <th class="text-center font-weight-bolder">Item Code</th>
                      <th class="text-center font-weight-bolder">
                        Item Description
                      </th>
                      <th class="text-center font-weight-bolder">Unit Price</th>
                      <th class="text-center font-weight-bolder">IN QTY</th>
                      <th class="text-center font-weight-bolder">Gross Amt</th>
                      <th class="text-center font-weight-bolder">Disc Rate</th>
                      <th class="text-center font-weight-bolder">Disc Amt</th>
                      <th class="text-center font-weight-bolder">
                        After Disc Amt
                      </th>
                      <th class="text-center font-weight-bolder">Tax Code</th>
                      <th class="text-center font-weight-bolder">Tax Amt</th>
                      <th class="text-center font-weight-bolder">Line Total</th>
                      <th class="text-center font-weight-bolder">
                        Cost Center
                      </th>
                      <!-- <th class="text-center font-weight-bolder">Account</th>
                      <th class="text-center font-weight-bolder">
                        Track Doc Number
                      </th>
                      <th class="text-center font-weight-bolder">
                        Track Doc Date
                      </th>
                      <th class="text-center font-weight-bolder">Upload Time</th> -->
                    </tr>
                  </thead>
                  <tbody
                    v-if="erp.invoice_details && erp.invoice_details.length"
                  >
                    <tr
                      v-for="(invoice, index) in erp.invoice_details"
                      :key="index"
                      :class="{
                        'total-row': index + 1 == erp.invoice_details.length,
                      }"
                    >
                      <td class="text-left text-xss w-25">
                        {{ invoice.displaysequence }}
                      </td>
                      <td class="text-left text-xss w-25">
                        <div v-if="isEdit">
                          <material-input
                            v-model="invoice.itemcode"
                            type="text"
                            id="item_code"
                            name="item_code"
                          />
                        </div>
                        <div v-else>
                          {{ invoice.itemcode }}
                        </div>
                      </td>
                      <td class="text-left text-xss w-25">
                        <div v-if="isEdit">
                          <material-input
                            v-model="invoice.itemdescription"
                            type="text"
                            id="item_description"
                            name="item_description"
                          />
                        </div>
                        <div v-else>
                          {{ invoice.itemdescription }}
                        </div>
                      </td>
                      <td class="text-left text-xss w-25">
                        <div v-if="isEdit">
                          <material-input
                            v-model="invoice.transactionunitprice"
                            type="text"
                            id="transaction_unit_price"
                            name="transaction_unit_price"
                          />
                        </div>
                        <div v-else>
                          {{ formatPrice(invoice.transactionunitprice) }}
                        </div>
                      </td>
                      <td class="text-left text-xss w-25">
                        <div v-if="isEdit">
                          <material-input
                            v-model="invoice.quantityinvoice"
                            type="text"
                            id="quantity_invoice"
                            name="quantity_invoice"
                          />
                        </div>
                        <div v-else>
                          {{ invoice.quantityinvoice }}
                        </div>
                      </td>
                      <td class="text-left text-xss w-25">
                        <div v-if="isEdit">
                          <material-input
                            v-model="invoice.transactiongrossamount"
                            type="text"
                            id="transaction_gross_amount"
                            name="transaction_gross_amount"
                          />
                        </div>
                        <div v-else>
                          {{ formatPrice(invoice.transactiongrossamount) }}
                        </div>
                      </td>
                      <td class="text-left text-xss w-25">
                        <div v-if="isEdit">
                          <material-input
                            v-model="invoice.discountrate"
                            type="text"
                            id="discount_rate"
                            name="discount_rate"
                          />
                        </div>
                        <div v-else>
                          {{
                            invoice.discountrate != ""
                              ? invoice.discountrate * 100 + "%"
                              : ""
                          }}
                        </div>
                      </td>
                      <td class="text-left text-xss w-25">
                        <div v-if="isEdit">
                          <material-input
                            v-model="invoice.transactiondiscountamount"
                            type="text"
                            id="transaction_discount_amount"
                            name="transaction_discount_amount"
                          />
                        </div>
                        <div v-else>
                          {{ formatPrice(invoice.transactiondiscountamount) }}
                        </div>
                      </td>
                      <td class="text-left text-xss w-25">
                        <div v-if="isEdit">
                          <material-input
                            v-model="invoice.transactionafterdiscountamount"
                            type="text"
                            id="transaction_after_discount_amount"
                            name="transaction_after_discount_amount"
                          />
                        </div>
                        <div v-else>
                          {{
                            formatPrice(invoice.transactionafterdiscountamount)
                          }}
                        </div>
                      </td>
                      <td class="text-left text-xss w-25">
                        <div v-if="isEdit">
                          <material-input
                            v-model="invoice.taxcode"
                            type="text"
                            id="tax_code"
                            name="tax_code"
                          />
                        </div>
                        <div v-else>
                          {{ invoice.taxcode }}
                        </div>
                      </td>
                      <td class="text-left text-xss w-25">
                        <div v-if="isEdit">
                          <material-input
                            v-model="invoice.transactiontaxamount"
                            type="text"
                            id="transaction_tax_amount"
                            name="transaction_tax_amount"
                          />
                        </div>
                        <div v-else>
                          {{ formatPrice(invoice.transactiontaxamount) }}
                        </div>
                      </td>
                      <td class="text-left text-xss w-25">
                        <div v-if="isEdit">
                          <material-input
                            v-model="invoice.transactionaftertaxamount"
                            type="text"
                            id="transaction_after_tax_amount"
                            name="transaction_after_tax_amount"
                          />
                        </div>
                        <div v-else>
                          {{ formatPrice(invoice.transactionaftertaxamount) }}
                        </div>
                      </td>
                      <td class="text-left text-xss w-25">
                        <div v-if="isEdit">
                          <material-input
                            v-model="invoice.costcentercode"
                            type="text"
                            id="cost_center_code"
                            name="cost_center_code"
                          />
                        </div>
                        <div v-else>
                          {{ invoice.costcentercode }}
                        </div>
                      </td>
                      <!-- <td class="text-left text-xss w-25"></td>
                      <td class="text-left text-xss w-25"></td>
                      <td class="text-left text-xss w-25"></td>
                      <td class="text-left text-xss w-25">
                        {{ invoice.created_at_format }}
                      </td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="general mt-5">
              <div class="d-flex align-items-center justify-content-end">
                <div class="me-3 font-weight-bold">Rounding:</div>
                <div v-if="isEdit" style="width: 150px">
                  <material-input
                    v-model="erp.transactroundingamount"
                    type="text"
                    id="transactroundingamount"
                    name="transactroundingamount"
                  />
                </div>
                <div v-else style="width: 150px">
                  {{ formatPrice(erp.transactroundingamount) }}
                </div>
              </div>
              <div class="d-flex justify-content-end mt-3">
                <div class="me-3 font-weight-bold">Grand Total:</div>
                <div style="width: 150px">
                  {{ formatPrice(erp.transactroundingamount + total) }}
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <!-- <button
              v-if="this.erp.uploaded != 1"
              type="button"
              class="btn btn-success btn-background btn-sm"
              @click="uploadErp()"
            >
              Upload
            </button> -->
            <button
              v-if="isEdit"
              type="button"
              class="btn btn-success btn-background btn-sm"
              @click="saveDraft()"
            >
              Save Draft
            </button>
            <!-- <button type="button" class="btn btn-success btn-background btn-sm" @click="updateErp()">
              Update
            </button> -->
            <button
              type="button"
              class="btn btn-primary btn-close-modal btn-sm"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
          <material-loading :active="loaderActive" />
          <div class="position-fixed top-1 end-1 z-index-9999 message-success" style="z-index: 9999999999;">
            <material-snackbar
              v-if="snackbar === 'danger' || snackbar === 'success'"
              title=""
              date=""
              :description="message"
              :icon="{ component: 'campaign', color: 'white' }"
              :color="snackbar"
              :close-handler="closeSnackbar"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import AnalyticsService from "../../../services/analytics.service";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import $ from "jquery";
import CommonFunctionService from "../../../services/commonFunction.service";

export default {
  name: "ModalErpDetail",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialInput,
    Datepicker,
    MaterialCheckbox,
  },
  props: {
    keyReload: {
      type: String,
      required: true,
    },
    idSelected: {
      type: Number,
      default: 0,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    isDraft: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      message: "",
      snackbar: null,
      loaderActive: false,
      erp: {
        customercode: "",
        credittermcode: "",
        basecurrency: "",
        documentnumber: "",
        deliverymodecode: "",
        documentdate: null,
        costcentercode: "",
        remarks: "",
        documentreference: "",
        permitnumber: "",
        teammember: "",
        exclusivetax: 0,
        uploaded: 1,
      },
      total: 0,
      fieldRequired: {},
    };
  },
  methods: {
    format(date) {
      return CommonFunctionService.formatDateShow(date);
    },

    formatPrice(price) {
      if (price === "") {
        return price;
      }

      price = parseFloat(price).toFixed(2);
      return (
        "S$ " + price.toLocaleString("en-US", { minimumFractionDigits: 2 })
      );
    },

    getErpDetail() {
      if (this.idSelected) {
        this.showLoader();

        AnalyticsService.erpDetail(this.idSelected).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.erp = response.data.data;

              if (
                this.erp &&
                this.erp.invoice_details &&
                this.erp.invoice_details.length
              ) {
                this.total = this.erp.invoice_details[
                  this.erp.invoice_details.length - 1
                ].transactionaftertaxamount;
              }
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      }
    },

    updateErp() {
      if (this.idSelected) {
        this.showLoader();
        this.erp.invoice_details.splice(-1, 1);

        AnalyticsService.updateErp(this.erp).then(
          (response) => {
            if (response.data.result.isSuccess) {
              $(".btn-close-modal").click();
              this.$emit("reload-data", "Update Erp Success");
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      }
    },

    uploadErp() {
      if (this.processValidate()) {
        this.showLoader();
        var dataForm = {
          id: this.idSelected,
        };

        AnalyticsService.uploadErp(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              $(".btn-close-modal").click();
              this.$emit("reload-data", "Invoice submitted successfully!");
            } else {
              this.snackbar = "danger";
              this.message =
                "Invoice submitted failed. Error Message: " +
                response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      }
    },

    saveDraft() {
      if (this.processValidate()) {
        if (this.idSelected) {
          this.showLoader();
          this.erp.invoice_details.splice(-1, 1);

          AnalyticsService.saveDraft(this.erp).then(
            (response) => {
              if (response.data.result.isSuccess) {
                $(".btn-close-modal").click();
                this.$emit(
                  "reload-data",
                  "Save Draft Success",
                  response.data.data
                );
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
              this.hideLoader();
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.hideLoader();
              this.setTimeOutNotification();
            }
          );
        }
      }
    },

    resetRequired() {
      this.fieldRequired.isDocumentNumberRequired = false;
    },

    processValidate() {
      this.resetRequired();
      var isValid = true;

      if (this.erp.documentnumber == "" || this.erp.documentnumber == null) {
        this.fieldRequired.isDocumentNumberRequired = true;
        isValid = false;
      }

      return isValid;
    },

    closeSnackbar() {
      this.snackbar = null;
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
  },
  watch: {
    keyReload() {
      this.resetRequired();
      this.erp = {
        customercode: "",
        credittermcode: "",
        basecurrency: "",
        documentnumber: "",
        deliverymodecode: "",
        documentdate: null,
        costcentercode: "",
        remarks: "",
        documentreference: "",
        permitnumber: "",
        teammember: "",
        exclusivetax: 0,
        uploaded: 1
      };
      this.getErpDetail();
    },
  },
};
</script>

<style scoped>
.modal {
  z-index: 99999;
}

.modal-dialog {
  max-width: 75vw;
}

.modal-body {
  max-height: 75vh;
  overflow-y: auto;
  padding-bottom: 25px;
}

.date-time-dashboad .dp__input_wrap {
  min-width: 200px !important;
  width: 250px;
}

.table-responsive::-webkit-scrollbar {
  height: 7px;
}

.table-responsive::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.table-responsive::-webkit-scrollbar-thumb {
  background: #888;
}

.table-responsive::-webkit-scrollbar-thumb:hover {
  background: #555;
}

tbody > tr.total-row {
  font-weight: bold;
  color: #222;
}

.text-success {
  color: #28a745 !important;
}
</style>
