import api from './api';

class QRService {
  getBranchInfo(dataForm) {
    return api.post('/merchant/qr/get-branch', dataForm);
  }

  generateQR(dataForm) {
    return api.post('/merchant/qr/generate', dataForm);
  }

  generateQueueQR(dataForm) {
    return api.post('/merchant/queue-qr/generate', dataForm);
  }
}

export default new QRService();
