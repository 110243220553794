<template>
  <div>
    <material-button
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#modalAddPrinter"
      id="show-modal-add-printer"
    ></material-button>
    <div
      class="modal fade"
      id="modalAddPrinter"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add Printer</h5>
            <i
              style="cursor: pointer"
              class="material-icons btn-close-modal justify-content-end"
              data-bs-dismiss="modal"
              aria-label="Close"
              >clear</i
            >
            </div>
            <div class="modal-body">
                <div>
                    <div class="d-flex" v-for="(value, key) in data" :key="key" style="margin-top: 10px;">
                        <material-checkbox
                            :checked="
                                printerSelected.includes(value.id)
                            "
                            @input="
                                selectPrinter($event, value.id)
                            "
                            class="align-items-center checkbox-custom mb-0 ms-3"
                        ></material-checkbox>
                        {{value.name}}
                    </div>
                </div>
                <div class="d-flex" style="margin-top: 20px;">
                    <material-button
                        class="mx-2 btn btn-sm btn-background"
                        @click="savePrinter()"
                    >
                        Save
                    </material-button>
                    <material-button
                        class="mx-2 btn btn-sm btn-background"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    >
                        Cancel
                    </material-button>
                </div>
            </div>
        </div>
      </div>
    </div>
    <material-loading :active="loaderActive" />
  </div>
</template>
<script>
import $ from "jquery";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import MenusetService from "../services/product/menuset.service";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";

export default {
    name: "create-category",
    components: {
        MaterialButton,
        MaterialLoading,
        MaterialCheckbox
    },
    props: {
        keyReload: {
            type: String,
            required: true,
        },
        categoryType: {
            type: Number,
            required: true,
        },
        isEditCategory: {
            type: Boolean,
            default: false
        },
        categoryId: {
            type: Number,
            default: 0
        },
        setId: {
            type: Number,
            default: 0
        },
        isFeature: {
            type: Number,
            default: 0
        },
        printerSelectedParam: {
            type: Array
        },
        itemIdSelectedForPrinter: {
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            messageImageError: "",
            loaderActive: false,
            data: [],
            snackbar: null,
            printerSelected: []
        };
    },
    created() {
        this.getPrinters();
    },
    methods: {
        showLoader() {
            this.loaderActive = true;
        },

        hideLoader() {
            this.loaderActive = false;
        },

        setTimeOutNotification() {
            setTimeout(() => {
                this.snackbar = null;
            }, 3000);
        },
        async getPrinters() {
            this.printerSelected = this.printerSelectedParam;
            this.showLoader();
            var dataForm = {
                set_id: this.setId
            };

            await MenusetService.getPrinters(dataForm).then(
                (response) => {
                if (response.data.result.isSuccess) {
                    this.data = response.data.data;
                } else {
                    this.snackbar = "danger";
                    this.message = response.data.result.message;
                    this.setTimeOutNotification();
                }
                this.hideLoader();
                },
                (error) => {
                this.snackbar = "danger";
                this.message = error;
                this.hideLoader();
                this.setTimeOutNotification();
                }
            );
        },
        selectPrinter(event, printerId) {
            if (event.target.checked) {
                this.printerSelected.push(
                    printerId
                );
            } else {
                this.printerSelected.splice(
                    this.printerSelected.indexOf(
                        printerId
                    ),
                    1
                );
            }
        },
        savePrinter() {
            console.log(this.printerSelected);
            
            if (this.itemIdSelectedForPrinter && this.itemIdSelectedForPrinter > 0) {
                var data = {
                    menuset_id: this.setId,
                    item_id: this.itemIdSelectedForPrinter,
                    printer_array: this.printerSelected
                };
                this.showLoader();
                MenusetService.assignPrinterToItem(data).then(
                    (response) => {
                        if (response.data.result.isSuccess) {
                            this.snackbar = "success";
                            this.message = response.data.result.message;
                            this.setTimeOutNotification();
                            $(".btn-close-modal").click();
                            this.$emit('reload-list-category', 'Save Successfully');
                        } else {
                            this.snackbar = "danger";
                            this.message = response.data.result.message;
                            this.setTimeOutNotification();
                        }
                        this.hideLoader();
                    },
                    (error) => {
                        this.snackbar = "danger";
                        this.message = error;
                        this.hideLoader();
                        this.setTimeOutNotification();
                    }
                );
            } else {
                var data1 = {
                    category_id: this.categoryId,
                    printer_array: this.printerSelected
                };
                this.showLoader();
                MenusetService.assignPrinterToCategory(data1).then(
                    (response) => {
                        if (response.data.result.isSuccess) {
                            this.snackbar = "success";
                            this.message = response.data.result.message;
                            this.setTimeOutNotification();
                            $(".btn-close-modal").click();
                            this.$emit('reload-list-category', 'Save Successfully');
                        } else {
                            this.snackbar = "danger";
                            this.message = response.data.result.message;
                            this.setTimeOutNotification();
                        }
                        this.hideLoader();
                    },
                    (error) => {
                        this.snackbar = "danger";
                        this.message = error;
                        this.hideLoader();
                        this.setTimeOutNotification();
                    }
                );
            }
        }
    },
    watch: {
        async keyReload() {
            await this.getPrinters();
        }
    },
};
</script>
<style scoped>
.modal-body {
  max-height: 85vh;
  overflow-y: auto;
  padding-bottom: 25px;
}

.modal-body .row {
  margin-top: 10px;
}
.content {
  cursor: pointer;
  text-align: center;
  width: 250px;
  height: 250px;
  margin-top: 25px;
  border: 1px solid #ccc;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #f0f2f5;
}
.font-18 {
  font-size: 18px;
}

.dowload-img {
  text-align: center;
  cursor: pointer;
  background-color: #f0f2f5;
  width: 100%;
  height: 300px;
}

.css-icon-upload {
  font-size: 45px !important;
  margin-top: 6rem;
}

.image-url {
  width: 100%;
  height: 300px;
}

.btn-edit-img {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: #f44335;
  font-size: 14px;
  right: 30px;
  top: 4px;
  background: #ffffff;
  border-radius: 50%;
}
</style>
