<template>
  <div>
    <button
      style="display: none"
      type="button"
      class="show-modal-erp-upload-response"
      data-bs-toggle="modal"
      data-bs-target="#modalErpUploadResponse"
    ></button>
    <div
      class="modal fade"
      id="modalErpUploadResponse"
      tabindex="-1"
      aria-labelledby="modalErpUploadResponseLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title me-3">Invoice Upload Response</h5>
            <i
              style="cursor: pointer"
              class="material-icons btn-close-modal justify-content-end"
              data-bs-dismiss="modal"
              >clear</i
            >
          </div>
          <div class="modal-body">
            <div class="table-responsive p-0 custom-table">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th class="text-center font-weight-bolder w-10">Row No</th>
                    <th class="text-center font-weight-bolder">Date Time</th>
                    <th class="text-center font-weight-bolder">Type</th>
                    <th class="text-center font-weight-bolder">Response</th>
                  </tr>
                </thead>
                <tbody v-if="responses && responses.length">
                  <tr v-for="(response, index) in responses" :key="index">
                    <td class="text-left text-xss w-10">
                      {{ index + 1 }}
                    </td>
                    <td class="text-left text-xss w-20">
                      {{ response.created_at_format }}
                    </td>
                    <td class="text-left text-xss w-20">
                      {{ response.type == 2 ? "Receipt" : "Invoice" }}
                    </td>
                    <td class="text-left text-xss w-50">
                      <pre>{{ JSON.parse(response.uploadresponse) }}</pre>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="4" class="text-center">No item available</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <material-loading :active="loaderActive" />
          <div
            class="position-fixed top-1 end-1 z-index-9999 message-success"
            style="z-index: 9999999999"
          >
            <material-snackbar
              v-if="snackbar === 'danger' || snackbar === 'success'"
              title=""
              date=""
              :description="message"
              :icon="{ component: 'campaign', color: 'white' }"
              :color="snackbar"
              :close-handler="closeSnackbar"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import AnalyticsService from "../../../services/analytics.service";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  name: "ModalErpDetail",
  components: {
    MaterialSnackbar,
    MaterialLoading,
  },
  props: {
    keyReload: {
      type: String,
      required: true,
    },
    idSelected: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      message: "",
      snackbar: null,
      loaderActive: false,
      responses: [],
    };
  },
  methods: {
    erpUploadResponse() {
      if (this.idSelected) {
        this.showLoader();

        AnalyticsService.erpUploadResponse(this.idSelected).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.responses = response.data.data;
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      }
    },

    closeSnackbar() {
      this.snackbar = null;
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
  },
  watch: {
    keyReload() {
      this.responses = [];
      this.erpUploadResponse();
    },
  },
};
</script>

<style scoped>
.modal {
  z-index: 99999;
}

.modal-dialog {
  max-width: 50vw;
}

.modal-body {
  max-height: 75vh;
  overflow-y: auto;
  padding-bottom: 25px;
}

.date-time-dashboad .dp__input_wrap {
  min-width: 200px !important;
  width: 250px;
}

.table-responsive::-webkit-scrollbar {
  height: 7px;
}

.table-responsive::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.table-responsive::-webkit-scrollbar-thumb {
  background: #888;
}

.table-responsive::-webkit-scrollbar-thumb:hover {
  background: #555;
}

tbody > tr.total-row {
  font-weight: bold;
  color: #222;
}

.text-success {
  color: #28a745 !important;
}
</style>
