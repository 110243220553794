<template>
  <div>
    <material-button
      class="d-none show-modal-choose-upload-option"
      data-bs-toggle="modal"
      data-bs-target="#modalChooseUploadOption"
    ></material-button>
    <material-button
      class="d-none hide-modal-choose-upload-option"
      data-bs-dismiss="modal"
      data-bs-target="#modalChooseUploadOption"
    ></material-button>
    <div
      class="modal fade"
      id="modalChooseUploadOption"
      tabindex="-1"
      aria-hidden="true"
      ref="modalChooseUploadOption"
      style="z-index: 9999"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style="width: unset">
          <div class="modal-body" style="overflow-y: unset">
            <div class="d-flex">
              <h5 class="modal-title" style="width: 95.6667%; cursor: pointer">
                Choose an option
              </h5>
              <i
                style="cursor: pointer"
                class="material-icons btn-close-modal justify-content-end"
                data-bs-dismiss="modal"
                aria-label="Close"
                >clear</i
              >
            </div>
            <div class="d-flex">
              <div class="content" @click="confirmUploadErp(1)">
                <div>
                  <h5>All</h5>
                  <i class="material-icons css-icon">cloud_upload</i>
                </div>
              </div>
              <div class="content" @click="confirmUploadErp(2)">
                <div>
                  <h5>Inv Only</h5>
                  <i class="material-icons css-icon">cloud_upload</i>
                </div>
              </div>
              <div class="content" @click="confirmUploadErp(3)">
                <div>
                  <h5>Receipt Only</h5>
                  <i class="material-icons css-icon">cloud_upload</i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";

export default {
  name: "modal-choose-upload-option",
  components: {
    MaterialButton,
  },
  props: {
    confirmUploadErp: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style scoped>
.modal-body {
  max-height: 75vh;
  overflow-y: auto;
  padding-bottom: 25px;
}

.modal-body .row {
  margin-top: 10px;
}

.content {
  cursor: pointer;
  text-align: center;
  width: 200px;
  height: 200px;
  margin-top: 25px;
  border: 1px solid #ccc;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #f0f2f5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.css-icon {
  font-size: 50px !important;
}
</style>
