import api from './api';

class AnalyticsService {
    dailyCollectionsReport(dataForm) {
        return api.post('/merchant/analytics/daily-collections', dataForm);
    }

    collectionByBranchReport(dataForm) {
        return api.post('/merchant/analytics/collection-by-branch', dataForm);
    }

    itemSalesByBranchReport(dataForm) {
        return api.post('/merchant/analytics/item-sales-by-branch', dataForm);
    }

    itemSalesSummaryReport(dataForm) {
        return api.post('/merchant/analytics/item-sales-summary', dataForm);
    }

    posDailyCollectionsReport(dataForm) {
        return api.post('/merchant/analytics/pos-daily-collections', dataForm);
    }

    erpCollectionsReport(dataForm) {
        return api.post('/merchant/analytics/erp-collections', dataForm);
    }

    erpDetail(id) {
        return api.post('/merchant/analytics/erp/detail/' + id);
    }

    updateErp(dataForm) {
        return api.post('/merchant/analytics/erp/update', dataForm);
    }

    saveDraft(dataForm) {
        return api.post('/merchant/analytics/erp/save-draft', dataForm);
    }

    uploadErp(dataForm) {
        return api.post('/merchant/analytics/erp/upload', dataForm);
    }

    deleteErp(id) {
        return api.post('/merchant/analytics/erp/delete/' + id);
    }

    getLoginCredentials() {
        return api.get('/merchant/analytics/erp/get-login-credentials');
    }

    saveLoginCredentials(dataForm) {
        return api.post('/merchant/analytics/erp/save-login-credentials', dataForm);
    }

    erpUploadResponse(id) {
        return api.post('/merchant/analytics/erp/' + id + '/upload-response');
    }
}

export default new AnalyticsService();