<template>
  <div>
    <button
      style="display: none"
      type="button"
      class="btn-save-draft-success"
      data-bs-toggle="modal"
      data-bs-target="#modalSaveDraftSuccess"
    ></button>
    <button
      style="display: none"
      type="button"
      class="btn-close-save-draft-success"
      data-bs-dismiss="modal"
      data-bs-target="#modalSaveDraftSuccess"
    ></button>
    <div
      class="modal fade"
      id="modalSaveDraftSuccess"
      tabindex="-1"
      aria-labelledby="modalSaveDraftSuccess"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">Invoice has been saved as draft with Document Number: {{ id }}</div>
          <div
            class="modal-footer d-flex justify-content-between align-items-center"
          >
            <div class="tag-a-custom" @click="gotoErpDraft()">Go to Erp Draft</div>
            <button
              type="button"
              class="btn btn-primary btn-close-modal btn-sm mb-0"
              data-bs-dismiss="modal"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "ModalSaveDraftSuccess",
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  methods: {
    gotoErpDraft() {
      $('.btn-close-modal').click();
      this.$router.push('/erp/erp-draft');
    }
  }
};
</script>

<style scoped>
.tag-a-custom {
    color: #007bff !important;
    text-decoration: underline !important;
    cursor: pointer;
}
</style>
