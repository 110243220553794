<template>
  <div>
    <div class="page-header align-items-start min-vh-100">
      <span class="mask bg-white"></span>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12 mx-auto">
            <div class="mt-3">
              <div
                class="text-center label-form"
                style="font-size: 32px !important"
              >
                Queue QR Generating
              </div>
              <div v-if="generateDone" class="qr-box">
                <div class="mt-5">
                  <div id="printQR">
                    <div v-if="branchInfo.queue_qr_image" class="text-center">
                      <img
                        :src="branchInfo.queue_qr_image"
                        width="250"
                        height="250"
                        alt="error"
                      />
                    </div>
                    <div
                      class="font-weight-bold text-center mt-5"
                      style="font-size: 18px"
                    >
                      <div class="mt-2">Restaurant: {{ branchInfo.branch_name }}</div>
                      <div class="mt-2">How to order?</div>
                      <div class="mt-2">Scan QR code above</div>
                    </div>
                  </div>
                  <div class="text-center mt-5">
                    <material-button
                      v-print="printObj"
                      class="btn-background btn-sm"
                    >
                      Print
                    </material-button>
                  </div>
                  <div class="text-center mt-5">
                    <material-button
                      class="btn-background btn-sm"
                      @click="generateQueueQR(true)"
                    >
                      Regenerate
                    </material-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2 message-success">
    <material-snackbar
      v-if="snackbar === 'danger' || snackbar === 'success'"
      title
      date
      :description="message"
      :icon="{ component: 'campaign', color: 'white' }"
      :color="snackbar"
      :close-handler="closeSnackbar"
    />
  </div>
  <material-loading :active="loaderActive" />
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import QRService from "../../services/qr.service";
import "bootstrap/dist/js/bootstrap.bundle";

export default {
  name: "create-menu",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialButton,
  },
  data() {
    return {
      generateDone: false,
      snackbar: null,
      message: "",
      loaderActive: false,
      branchCode: "",
      merchantId: 0,
      branchInfo: {},
      printObj: {
        id: "printQR",
        popTitle: "STATIC QR",
      },
    };
  },
  created() {
    this.branchCode = this.$route.params.branch_code ?? "";
    this.merchantId = this.$route.params.merchant_id ?? 0;
  },
  mounted() {
    this.generateQueueQR();
  },
  methods: {
    generateQueueQR(regenerate = false) {
      this.showLoader();
      var dataForm = {
        merchant_id: this.merchantId,
        branch_code: this.branchCode,
        regenerate: regenerate
      }

      QRService.generateQueueQR(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.branchInfo = response.data.data;
            this.generateDone = true;
            this.snackbar = "success";
            this.message = "Generate Success";
            this.setTimeOutNotification();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
          this.hideLoader();
        }
      );
    },

    

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
  },
};
</script>
<style scoped>
.page-header .container {
  color: #222 !important;
}

.qr-box {
  display: flex;
  justify-content: center;
}

.qr-box > div {
  width: 100%;
  max-width: 600px;
}

.cl-22 {
  color: #222 !important;
}
</style>
