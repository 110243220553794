import api from './api';

class SettingGeneralService {
  getDataConfigs() {
    return api.get('/merchant/get-merchant-setting');
  }

  addDataConfigs(dataForm) {
    return api.post('/merchant/merchant-setting-create', dataForm, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  addSettingMerchantNotifications(dataForm) {
    return api.post('/merchant/add-setting-merchant-notifications', dataForm, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  addPaidMembershipRewardsByMerchant(dataForm) {
    return api.post('/merchant/add-paid-membership-rewards-by-merchant', dataForm);
  }

  listPaidMembershipRewardsByMerchant() {
    return api.get('/merchant/list-paid-membership-rewards-by-merchant');
  }

  deletePaidMembershipRewardsByMerchant(id) {
    return api.post('/merchant/delete-paid-membership-rewards-by-merchant/'+id);
  }

  masterData(dataForm) {
    return api.post('/merchant/master-data', dataForm);
  }

  updateKioskSettings(dataForm) {
    return api.post('/merchant/update-kiosk-setting', dataForm);
  }
}

export default new SettingGeneralService();
