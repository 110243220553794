<template>
  <div id="page-report">
    <div class="row mb-2 pe-4">
      <div class="col-lg-12 col-md-12 col-12 d-flex align-items-center">
        <div class="color-common">
          <h3 class="title-page mb-0 ps-3">ERP</h3>
        </div>
      </div>
    </div>
    <div class="page-header align-items-start min-vh-100 my-transacions">
      <span class="mask bg-white"></span>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12 mx-auto mt-4">
            <div>
              <h2>Login Credentials</h2>
            </div>
            <div class="mt-3 mw-500px">
              <label class="fw-bold" for>
                ERP's Api End Point
                <span class="text-danger ms-2">*</span>
              </label>
              <material-input
                v-model="form.api_end_point"
                type="text"
                id="api_end_point"
                name="api_end_point"
              />
              <span class="is-invalid" v-if="fieldRequired.isApiEndPointRequired"
                >The field is required.</span
              >
            </div>
            <div class="mt-3 mw-500px">
              <label class="fw-bold" for>
                Company Code
                <span class="text-danger ms-2">*</span>
              </label>
              <material-input
                v-model="form.company_code"
                type="text"
                id="company_code"
                name="company_code"
              />
              <span class="is-invalid" v-if="fieldRequired.isCompanyCodeRequired"
                >The field is required.</span
              >
            </div>
            <material-button
              class="btn btn-sm btn-background mt-3"
              @click="saveLoginCredentials()"
            >
              Save and login
            </material-button>
          </div>
        </div>
      </div>
      <div class="position-fixed top-1 end-1 z-index-2 message-success">
        <material-snackbar
          v-if="snackbar === 'danger' || snackbar === 'success'"
          title
          date
          :description="message"
          :icon="{ component: 'campaign', color: 'white' }"
          :color="snackbar"
          :close-handler="closeSnackbar"
        />
      </div>
      <material-loading :active="loaderActive" />
    </div>
  </div>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import AnalyticsService from "../../services/analytics.service";

export default {
  name: "login-credentials",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialButton,
    MaterialInput,
  },
  data() {
    return {
      message: "",
      snackbar: null,
      loaderActive: false,
      form: {
        api_end_point: "",
        company_code: "",
        token: "",
        authentication: "",
      },
      fieldRequired: {},
    };
  },
  created() {
    this.getLoginCredentials();
  },
  methods: {
    getLoginCredentials() {
      this.showLoader();

      AnalyticsService.getLoginCredentials().then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.form = response.data.data;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    saveLoginCredentials() {
      if (this.processValidate()) {
        this.showLoader();

        AnalyticsService.saveLoginCredentials(this.form).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.snackbar = "success";
              this.message = "Save Login Credentials Success";
              this.setTimeOutNotification();
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      }
    },

    resetRequired() {
      this.fieldRequired.isApiEndPointRequired = false;
      this.fieldRequired.isCompanyCodeRequired = false;
    },

    processValidate() {
      this.resetRequired();
      var isValid = true;

      if (this.form.api_end_point == "" || this.form.api_end_point == null) {
        this.fieldRequired.isApiEndPointRequired = true;
        isValid = false;
      }

      if (this.form.company_code == "" || this.form.company_code == null) {
        this.fieldRequired.isCompanyCodeRequired = true;
        isValid = false;
      }

      return isValid;
    },

    closeSnackbar() {
      this.snackbar = null;
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
  },
};
</script>

<style scoped>
.mw-500px {
  max-width: 500px !important;
}
</style>
